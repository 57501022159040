import React, {useState} from 'react'
import moment from 'moment'
import {
    Col,
    Row,
    List,
    Tag,
    Space,
    Checkbox,
    Button,
    Typography,
    message,
    Image,
    Divider,
    Modal,
    Form,
    Select,
    Input,
    Upload,
    Switch,
} from 'antd'
import {Spin} from 'antd'
import {useMutation, useQuery} from 'react-query'
import trustShield from '../../../trustShield.png'
import pro from '../../../pro.png'
import pro_plus from '../../../pro_plus.png'
import Item from './Item'
import * as userQueries from '../../../queries/user.queries'
import * as Styled from './userview-styled'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'

import {ImageViewer} from 'react-image-viewer-dv'
import {EyeOutlined} from '@ant-design/icons'
import ReactJson from 'react-json-view'
import ReactJsonViewCompare from 'react-json-view-compare'
import UserSettings from './UserSettingsView'

const {Title} = Typography
const {TextArea} = Input
function UserView({params}) {
    const [visible, setVisible] = React.useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false)
    // const [userStatus, setUserStatus] = React.useState(null)
    const navigate = useNavigate()

    const [isHovered, setIsHovered] = useState(false)

    const showModal = () => {
        setVisible(true)
    }

    const handleOk = () => {
        const token = localStorage.getItem('token')
        setConfirmLoading(true)
        axios
            .delete(
                `${process.env.REACT_APP_BASE_URL}/admin/user/${params.id}`,
                {
                    headers: {
                        Authorization: token,
                    },
                },
            )
            .then(() => {
                setVisible(false)
                setConfirmLoading(false)
                navigate('/user')
                window.location.reload()
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleCancel = () => {
        console.log('Clicked cancel button')
        setVisible(false)
    }

    const userQuery = useQuery(['id', params.id], userQueries.getUserById)
    const UserTustShield = useMutation(userQueries.setusertrustshield)
    const sendConfirmMutate = useMutation(userQueries.sendVerificationEmail)
    const deactivateUserMutate = useMutation(userQueries.deactiveUser)
    const usergoferTypeMutate = useMutation(userQueries.setUserGoferType)

    const user = userQuery.data?.data
    const userStatus = user?.aasm_state
    // if(user?.aasm_state){
    // setUserStatus(user?.aasm_state)
    // console.log("-------------------------------------")
    // console.log(userStatus)
    // console.log("-------------------------------------")
    // }

    function sendVerificationEmail() {
        sendConfirmMutate.mutate(
            {queryKey: {user_id: params.id}},
            {
                onSuccess: () => {
                    message.success('Email sent successfully!')
                },
            },
        )
    }
    function changeUserTustShield() {
        UserTustShield.mutate(
            {queryKey: {user_id: params.id}},
            {
                onSuccess: () => {
                    userQuery.refetch()
                },
            },
        )
    }
    const [payout, setPayout] = useState(false)
    const [showGopherTypeChangeModal, showGopherTypeModal] = useState(false)
    const [gopherSelectionChanged, setGopherSelectionChanged] = useState(false)
    const [subcriptype, setGoferType] = useState(0)
    const [trustShieldVerified, settrustShieldVerified] = useState(false)
    const [isDropdown, setIsDropdown] = useState(false)
    const [selectedReason, setSelectedReason] = useState('')
    const [otherDescription, setOtherDescription] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [UserDeactiveModal, setUserDeactiveModal] = useState(false)
    const [userSettingsModal, setUserSetingsModal] = useState(false)
    const [ModalTriggerFunction, setmodaltriggerfunction] = useState('')
    const [modalBody, setModalBody] = useState('')
    const [DeactivateNotes, setDeactivateNotes] = useState('')
    const [viewUserJson, setViewUserJson] = useState(false)
    const [userJson, setUserJson] = useState([])
    const [ooa, setOOA] = useState(false)
    const form = Form.useForm()
    const changeGoferType = e => {
        console.log('changeGoferType ====>', e)
        setGoferType(e)
        setGopherSelectionChanged(true)
        // user.gopher_type_id = e.value;
    }
    const onSaveForm = event => {
        event.preventDefault()
        console.log('subcriptype ====>', subcriptype)
        if (subcriptype == 0 || subcriptype == 1 || subcriptype == 2) {
            usergoferTypeMutate.mutate(
                {queryKey: {gopher_type_id: subcriptype, user_id: params.id}},
                {
                    onSuccess: res => {
                        showGopherTypeModal(false)
                        userQuery.refetch()
                        // setTimeout(() => { window.location.reload(false) }, 2000)
                        setGopherSelectionChanged(false)
                    },
                },
            )
        }
    }
    const GetUserJson = async () => {
        const token = localStorage.getItem('token')
        try {
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}/admin/user/json/${params.id}`,
                    {
                        headers: {
                            Authorization: token,
                        },
                    },
                )
                .then(({data}) => {
                    if (data.success) {
                        setViewUserJson(true)
                        setUserJson(data.data)
                    }
                })
                .catch(error => {
                    message.error(error.message)
                })
        } catch (error) {
            message.error(error.message)
        }
    }
    const setOoaBraodcast = value => {
        const token = localStorage.getItem('token')
        try {
            axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}/admin/user/set_ooa_broadcast/${params.id}`,
                    {
                        sub_ooa: value,
                    },
                    {
                        headers: {
                            Authorization: token,
                        },
                    },
                )
                .then(({data}) => {
                    if (data.success) {
                        message.success(
                            data.data.sub_ooa
                                ? 'User has been Subscribed for OOA Alert'
                                : 'User has been Unsubscribed for OOA Alert',
                        )
                        setOOA(data.data.sub_ooa)
                    }
                })
                .catch(error => {
                    message.error(error.message)
                })
        } catch (error) {
            message.error(error.message)
        }
    }

    const openUserSettings = async () => {
        getsettings(params.id)
        setUserSetingsModal(true)
    }
    const closeUserSettings = () => {
        setUserSetingsModal(false)
    }

    const getsettings = id => {
        const token = localStorage.getItem('token')
        try {
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}/admin/user/getuser-settings/${id}`,
                    {
                        headers: {
                            Authorization: token,
                        },
                    },
                )
                .then(({data}) => {
                    if (data.success) {
                        setOOA(data.data.sub_ooa)
                    }
                })
                .catch(error => {
                    message.error(error.message)
                })
        } catch (error) {
            message.error(error.message)
        }
    }
    function toggleUserState() {
        setPayout(true)
        setIsDropdown(true)
        const token = localStorage.getItem('token')
        const is_active = user.aasm_state === 'active' ? true : false
        deactivateUserMutate.mutate(
            {
                queryKey: {
                    user_id: params.id,
                    deactivate_user:
                        user.aasm_state === 'active' ? false : true,
                    deactivate_note: DeactivateNotes || '',
                    token: token,
                },
            },
            {
                onSuccess: () => {
                    userQuery.refetch()
                    message.success(
                        !is_active ? 'User Activated' : 'User Deactivated',
                    )
                },
            },
        )
        setPayout(false)
    }

    const onFinish = values => {
        console.log('Received values from form:', values)
        // You can handle the form values here, e.g., submit to an API
        setPayout(true)
        const token = localStorage.getItem('token')
        const is_active = user.aasm_state === 'active' ? true : false
        deactivateUserMutate.mutate(
            {
                queryKey: {
                    user_id: params.id,
                    deactivate_user:
                        user.aasm_state === 'active' ? false : true,
                    deactivate_values: values,
                    token: token,
                },
            },
            {
                onSuccess: () => {
                    userQuery.refetch()
                    message.success(
                        !is_active ? 'User Activated' : 'User Deactivated',
                    )
                },
            },
        )
        setPayout(false)
        setIsDropdown(false)
    }

    const handleActivated = () => {
        console.log('User Deactivated!')
        setPayout(true)
        setIsDropdown(false)
        const token = localStorage.getItem('token')
        const is_active = user.aasm_state === 'active' ? true : false
        deactivateUserMutate.mutate(
            {
                queryKey: {
                    user_id: params.id,
                    deactivate_user:
                        user.aasm_state === 'active' ? false : true,
                    token: token,
                },
            },
            {
                onSuccess: () => {
                    userQuery.refetch()
                    message.success(
                        !is_active ? 'User Activated' : 'User Deactivated',
                    )
                },
            },
        )
        setPayout(false)
        // setIsDropdown(true)
    }

    const handleDeactivateNotes = e => {
        setDeactivateNotes(e.target.value)
    }

    const handleReasonChange = value => {
        setSelectedReason(value)
        if (value !== 'other') {
            setOtherDescription('')
        }
    }

    if (userQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    if (payout === true) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    const order = user.orders_served
    const orderPlaced = user.orders_placed
    const flags = user.order_flags
    const reports = user.reports
    const reffered = user.referrals
    const invites = user.invites
    const userinfo = user.user_info
    const email_tracking = user?.email_tracker
    const business_address_info = user?.business_address
    const userAddress = user?.address
    const groferTypeArr = [
        {value: 0, label: 'Standard'},
        {value: 1, label: 'Gopher Pro'},
        {value: 2, label: 'Gopher Pro Plus'},
    ]
    const favGopher = user.fav_gophers
    const user_account_notes = user.user_account_notes

    function closeModal() {
        setOpenModal(false)
    }
    function openConfirmationModal(body, triggerfunction) {
        setModalBody(body)
        setmodaltriggerfunction(triggerfunction)
        setOpenModal(true)
    }

    function openUserDeactiveModal() {
        setUserDeactiveModal(true)
    }

    function closeUserDeactiveModal() {
        setUserDeactiveModal(false)
    }
    function confirmDeactivateUser() {
        if (DeactivateNotes) {
            toggleUserState()
            setDeactivateNotes('')
            setUserDeactiveModal(false)
        } else {
            message.error('Please provide deactivation notes.')
        }
    }
    function confirmModal() {
        if (ModalTriggerFunction == 'toggleUserState') {
            toggleUserState()
        }
        if (ModalTriggerFunction == 'changeUserTustShield') {
            changeUserTustShield()
        }
        setOpenModal(false)
    }
    return (
        <>
            <Row className="user__detail">
                <Col className="user__top">
                    <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}
                        header={<Title level={5}>Basic Info</Title>}>
                        {user.profile_pic_id && (
                            <Item
                                body={
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                        }}>
                                        <Image
                                            alt="User Image"
                                            width={120}
                                            height={120}
                                            className="profile__pic"
                                            src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}/${user.profile_pic_id}/profile.jpg`}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: '-8px',
                                                left: '8px',
                                                width: '100%',
                                                color: '#fff', // White text color
                                                textAlign: 'right',
                                                padding: '5px',
                                                fontSize: '14px',
                                            }}>
                                            {user.trust_shield_verified ||
                                            trustShieldVerified ? (
                                                <Image
                                                    alt="TrustShield Verified"
                                                    width={50}
                                                    height={55}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        right: '0',
                                                        float: 'top',
                                                    }}
                                                    src={trustShield}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: '25px',
                                                width: '100%',
                                                color: '#fff', // White text color
                                                textAlign: 'right',
                                                padding: '5px',
                                                fontSize: '14px',
                                            }}>
                                            {user.gopher_type_id == 1 ? (
                                                <Image
                                                    alt="TrustShield Verified"
                                                    width={40}
                                                    height={20}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        right: '0',
                                                        float: 'top',
                                                    }}
                                                    src={pro}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            {user.gopher_type_id == 2 ? (
                                                <Image
                                                    alt="TrustShield Verified"
                                                    width={50}
                                                    height={27}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-7px',
                                                        right: '-10px',
                                                        float: 'top',
                                                    }}
                                                    src={pro_plus}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                }
                                title="Profile Pic"
                            />
                        )}

                        {/* {user.profile_pic_id && (
                            <ImageViewer className="relative">
                                <div
                                    className="image-container w-40 h-40 overflow-hidden relative"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}>
                                    <img
                                        className="w-full h-full object-cover  rounded-[80%]"
                                        src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}/${user.profile_pic_id}/profile.jpg`}
                                        alt="pic"
                                    />
                                    <div className="image-overlay absolute top-0 left-0 w-full h-full bg-black opacity-0 transition-opacity duration-300 hover:opacity-50"></div>
                                    {isHovered && (
                                        <div className="flex  preview-icon absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                                            <EyeOutlined
                                                style={{
                                                    fontSize: '24px',
                                                    marginRight: 8,
                                                }}
                                            />
                                            <p>preview</p>
                                        </div>
                                    )}
                                </div>
                            </ImageViewer>
                        )} */}

                        <Item body={user.id} title="ID#" />

                        <Item
                            body={
                                user.aasm_state === 'active' ? (
                                    <Tag color="green">Active</Tag>
                                ) : (
                                    <Tag color="red">Deactive</Tag>
                                )
                            }
                            title="Profile Status"
                        />
                        {user.is_gopher && (
                            <Item
                                body={
                                    user.payout_status &&
                                    user.payout_status === 'Active' ? (
                                        <Tag color="green">Active</Tag>
                                    ) : (
                                        <Tag color="red">
                                            {user.payout_status}
                                        </Tag>
                                    )
                                }
                                title="Payout Status"
                            />
                        )}
                        <Item
                            body={
                                <div style={{textTransform: 'capitalize'}}>
                                    {user.first_name}
                                </div>
                            }
                            title="First name"
                        />
                        <Item
                            body={
                                <div style={{textTransform: 'capitalize'}}>
                                    {user.last_name}
                                </div>
                            }
                            title="Last name"
                        />
                        <Item body={user.telephone} title="Telephone" />
                        <Item body={user.email} title="Email" />
                        <Item
                            body={
                                user.confirmed_at ? (
                                    <Tag color="green">Yes</Tag>
                                ) : (
                                    <Tag color="red">No</Tag>
                                )
                            }
                            title="Email confirmed"
                        />

                        <Item
                            body={moment(user.date_of_birth).format(
                                'MM-DD-YYYY',
                            )}
                            title="Date of Birth"
                        />
                        <Item
                            title="Personal info"
                            body={userinfo?.personal_info || 'N/A'}
                        />
                    </List>
                </Col>
                <Col className="user__top">
                    <List size="small">
                        <Item
                            title="Profile Pic #"
                            body={user?.profile_pic_id || 'N/A'}
                        />
                        <Item
                            title="Total Ratings"
                            body={user.review_count || 0}
                        />
                        <Item
                            title="Average Rating"
                            body={Number(user.rating || 0).toFixed(1)}
                        />
                        <Item
                            title="Last check-in"
                            body={moment(user.current_sign_in_at).format(
                                'YYYY-MM-DD',
                            )}
                        />
                        <Item
                            title="Creation Date"
                            body={moment(user.created_at).format(
                                'YYYY-MM-DD (hh:mm:ss A)',
                            )}
                        />
                        <Item
                            title="Last Updated"
                            body={moment(user.updated_at).format(
                                'YYYY-MM-DD (hh:mm:ss A)',
                            )}
                        />
                        <Item
                            title="Discover Gopher"
                            body={user.discover_gopher}
                        />
                        <Item
                            title="Discover Gopher others"
                            body={user.discover_gopher_others || 'N/A'}
                        />
                        <Item
                            title="Requesting Primarly"
                            body={user.requesting_primarly}
                        />
                        <Item
                            title="Requesting Primarly Others"
                            body={user.requesting_primarly_others || 'N/A'}
                        />
                        {user.trust_shield_verified ? (
                            <>
                                <Item
                                    title="TrustShield Verified On"
                                    body={moment(
                                        user.trust_shield_verified_on,
                                    ).format('YYYY-MM-DD (hh:mm:ss A)')}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        {user.is_gopher ? (
                            <>
                                <Item
                                    title="Available for categories"
                                    body={user.gopher_selecttype || 'N/A'}
                                />
                                <Item
                                    title="Gopher Radius"
                                    body={user.radius || 'N/A'}
                                />
                                <Item
                                    title="Gopher Offer limit (Min)"
                                    body={user.offer_limit || 'N/A'}
                                />
                            </>
                        ) : null}
                    </List>
                </Col>
                <Col className="user__top">
                    <List size="small">
                        <Space direction="horizontal">
                            <List size="small">
                                <Item
                                    title="Line1"
                                    body={userAddress?.line1 || 'N/A'}
                                />
                                <Item
                                    title="Line2"
                                    body={userAddress?.line2 || 'N/A'}
                                />
                                <Item
                                    title="City"
                                    body={userAddress?.city || 'N/A'}
                                />
                                <Item
                                    title="State"
                                    body={userAddress?.state || 'N/A'}
                                />
                                <Item
                                    title="Zip Code"
                                    body={userAddress?.zip_code || 'N/A'}
                                />
                                <Item
                                    title="Gopher"
                                    body={<Checkbox checked={user.is_gopher} />}
                                />
                                <Item
                                    title="Gopher Type"
                                    body={
                                        user.is_gopher
                                            ? user.gopher_type_id == 0
                                                ? 'Standard'
                                                : user.gopher_type_id == 1
                                                ? 'Gopher Pro'
                                                : 'Gopher Pro +'
                                            : 'N/A'
                                    }
                                />
                                <Item
                                    title="Gopher Device"
                                    body={user.gopher_devicetype || 'N/A'}
                                />
                                <Item
                                    title="Gopher App Version"
                                    body={user.gopher_app_version || 'N/A'}
                                />
                                <Item
                                    title="Requester"
                                    body={
                                        <Checkbox checked={user.is_requester} />
                                    }
                                />
                                <Item
                                    title="Requester Device"
                                    body={user.requestor_devicetype || 'N/A'}
                                />
                                <Item
                                    title="Requester App Version"
                                    body={user.requestor_app_version || 'N/A'}
                                />
                            </List>
                        </Space>
                    </List>
                </Col>
                {userStatus != 'Deleted' ? (
                    <Col className="user__top">
                        <List
                            size="small"
                            bordered
                            style={{
                                background: '#fafafa',
                                boxShadow: '0 5px 10px #eee',
                            }}>
                            <List.Item hidden={user.confirmed_at}>
                                <Button
                                    type="primary"
                                    style={{width: '100%'}}
                                    onClick={sendVerificationEmail}
                                    loading={sendConfirmMutate.isLoading}>
                                    Send Email Verification
                                </Button>
                            </List.Item>

                            <List.Item>
                                <Button type="primary" style={{width: '100%'}}>
                                    <Link to={`/edit-user/${user.id}`}>
                                        {' '}
                                        Edit User{' '}
                                    </Link>
                                </Button>
                            </List.Item>

                            <List.Item>
                                {user.aasm_state === 'active' ? (
                                    <Button
                                        style={{
                                            height: 'max-content',
                                            width: '100%',
                                            backgroundColor: 'red',
                                            color: '#fff',
                                        }}
                                        onClick={() => openUserDeactiveModal()}>
                                        Deactivate User
                                    </Button>
                                ) : (
                                    <Button
                                        style={{
                                            height: 'max-content',
                                            width: '100%',
                                            backgroundColor: 'green',
                                            color: '#fff',
                                        }}
                                        onClick={() =>
                                            openConfirmationModal(
                                                'Are you sure you want to activate this user?',
                                                'toggleUserState',
                                            )
                                        }>
                                        {/* // onClick={handleActivated}> */}
                                        Activate User
                                    </Button>
                                )}
                            </List.Item>

                            {/* add */}
                            {/* {isDropdown && user.aasm_state === 'active' ? (
                                <List.Item>
                                    <Form
                                        onFinish={onFinish}
                                        className="w-full">
                                        <Form.Item
                                            name="reason"
                                            label="Reason"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select a reason',
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Select a reason"
                                                onChange={handleReasonChange}
                                                value={selectedReason}>
                                                <Select.Option value="excessiveCancellation">
                                                    Excessive Cancellations
                                                </Select.Option>
                                                <Select.Option value="termsAndConditionsViolations">
                                                    Terms and Conditions Policy
                                                    Violations
                                                </Select.Option>
                                                <Select.Option value="other">
                                                    Other
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        {selectedReason === 'other' && (
                                            <Form.Item
                                                className={``}
                                                name="description"
                                                label="Description"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please provide descriptions',
                                                    },
                                                ]}>
                                                <Input.TextArea
                                                    placeholder="Please provide a description"
                                                    value={otherDescription}
                                                    onChange={e =>
                                                        setOtherDescription(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        )}
                                        <Form.Item
                                            name="document"
                                            label="Add Documentations"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => e.fileList}>
                                            <Upload
                                                name="document"
                                                action="/your-upload-endpoint" // Replace with your actual upload endpoint
                                                listType="text"
                                                beforeUpload={() => false} // Prevent default upload behavior
                                                accept=".pdf" // Specify the accepted file type(s)
                                            >
                                                <Button
                                                    icon={
                                                        <BsFiletypeDoc
                                                            size={28}
                                                        />
                                                    }>
                                                  
                                                </Button>
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item>
                                            <button
                                                type="submit"
                                                class="bg-transparent hover:bg-blue-500  text-blue-700  hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded">
                                                Submit
                                            </button>
                                        </Form.Item>
                                    </Form>
                                </List.Item>
                            ) : null} */}

                            <List.Item>
                                <Modal
                                    title="Delete User"
                                    visible={visible}
                                    onOk={handleOk}
                                    onCancel={handleCancel}>
                                    <p>
                                        Are You sure You want to delete this
                                        User ?
                                    </p>{' '}
                                </Modal>{' '}
                                <Button
                                    style={{
                                        height: 'max-content',
                                        width: '100%',
                                        backgroundColor: 'red',
                                        color: '#fff',
                                    }}
                                    onClick={showModal}>
                                    Delete User{' '}
                                </Button>{' '}
                            </List.Item>

                            {user.is_gopher ? (
                                <List.Item>
                                    <Button
                                        type="primary"
                                        style={{width: '100%'}}
                                        onClick={() =>
                                            showGopherTypeModal(true)
                                        }>
                                        Change Gopher Type
                                    </Button>
                                </List.Item>
                            ) : null}

                            <List.Item>
                                <Button
                                    type="primary"
                                    style={{width: '100%'}}
                                    onClick={() => GetUserJson()}>
                                    View User's JSON Version
                                </Button>
                            </List.Item>
                            {user.is_gopher && (
                                <List.Item>
                                    <Button
                                        type="primary"
                                        style={{width: '100%'}}
                                        onClick={openUserSettings}>
                                        Gopher Settings
                                    </Button>
                                </List.Item>
                            )}
                            {user.is_requester &&
                            user.is_trustshield_verified_user ? (
                                <List.Item>
                                    <Button
                                        type="primary"
                                        style={{width: '100%'}}
                                        onClick={() => {
                                            user.trust_shield_verified
                                                ? openConfirmationModal(
                                                      'Are you sure you want to Trust Shield Unverified for this user?',
                                                      'changeUserTustShield',
                                                  )
                                                : openConfirmationModal(
                                                      'Are you sure you want to Trust Shield Verified for this user?',
                                                      'changeUserTustShield',
                                                  )
                                        }}>
                                        {user.trust_shield_verified
                                            ? 'Trust Shield Unverified'
                                            : 'Trust Shield Verified'}
                                    </Button>
                                </List.Item>
                            ) : null}
                            <Modal
                                visible={showGopherTypeChangeModal}
                                onCancel={() => showGopherTypeModal(false)}
                                onOk={onSaveForm}>
                                <Form
                                    name="subcriptype"
                                    labelCol={{span: 8}}
                                    wrapperCol={{span: 16}}
                                    initialValues={{remember: true}}
                                    form={form[0]}
                                    autoComplete="off">
                                    <Form.Item>
                                        <Select
                                            id="gopher_type"
                                            name="gopher_type"
                                            placeholder="Select Option"
                                            value={groferTypeArr.find(
                                                obj =>
                                                    obj.value ===
                                                    (gopherSelectionChanged
                                                        ? subcriptype
                                                        : user.gopher_type_id),
                                            )} // set selected value
                                            options={groferTypeArr} // set list of the data
                                            onChange={changeGoferType} // assign onChange function
                                        />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </List>
                    </Col>
                ) : (
                    ''
                )}
            </Row>
            <>
                {userinfo && userinfo.length !== 0 && (
                    <Row>
                        {user.is_gopher ? (
                            <Col className="user__top">
                                <List
                                    size="small"
                                    bordered
                                    style={{background: 'rgba(0,0,0,0.03)'}}
                                    header={
                                        <Title level={5}>
                                            Available for sub categories
                                        </Title>
                                    }>
                                    <Item
                                        body={
                                            user.gopher_selectsubtype
                                                ? user.gopher_selectsubtype.split(
                                                      ',',
                                                  )
                                                : 'N/A'
                                        }
                                    />
                                </List>
                            </Col>
                        ) : null}
                        <Col className="user__top">
                            <List
                                size="small"
                                bordered
                                style={{background: 'rgba(0,0,0,0.03)'}}
                                header={
                                    <Title level={5}>Ride Sharing Info</Title>
                                }>
                                <Item
                                    title="Sharing"
                                    body={userinfo?.ride_sharing ? 'Yes' : 'No'}
                                />
                                <Item
                                    title="Car Insurance"
                                    body={userinfo.car_insurance ? 'Yes' : 'No'}
                                />
                                <Item
                                    title="Driving License"
                                    body={
                                        userinfo.driver_license ? 'Yes' : 'No'
                                    }
                                />
                                <Item
                                    title="Max passengers"
                                    body={userinfo.max_passengers || 'N/A'}
                                />
                                <Item
                                    title="Car Model"
                                    body={userinfo.model || 'N/A'}
                                />
                                <Item
                                    title="License Plate"
                                    body={
                                        userinfo.license_plate_number || 'N/A'
                                    }
                                />
                                <Item
                                    title="Make"
                                    body={
                                        userinfo?.make ? userinfo.make : 'N/A'
                                    }
                                />
                                <Item
                                    title="License Bounded Insured"
                                    body={
                                        userinfo?.license_bounded_insured
                                            ? 'Yes'
                                            : 'No'
                                    }
                                />
                                <Item
                                    title="Driver Licence Number"
                                    body={
                                        userinfo?.driver_license_number || 'N/A'
                                    }
                                />
                                <Item
                                    title="Driver Licence State"
                                    body={
                                        userinfo?.driver_license_state || 'N/A'
                                    }
                                />
                            </List>
                        </Col>

                        <Col className="user__top">
                            <List
                                size="small"
                                bordered
                                style={{background: 'rgba(0,0,0,0.03)'}}
                                header={<Title level={5}>Business Info</Title>}>
                                <Item
                                    title="Company Name"
                                    body={
                                        userinfo.business_name
                                            ? userinfo.business_name
                                            : 'N/A'
                                    }
                                />
                                <Item
                                    title="Comany Title"
                                    body={userinfo.company_title || 'N/A'}
                                />
                                <Item
                                    title="About Company"
                                    body={
                                        userinfo.about_company
                                            ? userinfo.about_company
                                            : 'N/A'
                                    }
                                />
                            </List>
                        </Col>

                        <Col className="user__top">
                            <List
                                size="small"
                                bordered
                                style={{background: 'rgba(0,0,0,0.03)'}}
                                header={
                                    <Title level={5}>Business Address</Title>
                                }>
                                <Item
                                    title="Line 1"
                                    body={
                                        business_address_info?.line1
                                            ? business_address_info?.line1
                                            : 'N/A'
                                    }
                                />
                                <Item
                                    title="Line 2"
                                    body={
                                        business_address_info?.line2
                                            ? business_address_info?.line2
                                            : 'N/A'
                                    }
                                />
                                <Item
                                    title="State"
                                    body={business_address_info?.state || 'N/A'}
                                />
                                <Item
                                    title="City"
                                    body={
                                        business_address_info?.city
                                            ? business_address_info.city
                                            : 'N/A'
                                    }
                                />
                                <Item
                                    title="Pin Code"
                                    body={
                                        business_address_info?.zip_code
                                            ? business_address_info.zip_code
                                            : 'N/A'
                                    }
                                />
                            </List>
                        </Col>
                        {user_account_notes && user_account_notes.length ? (
                            <Col className="user__top">
                                <div style={{margin: '1px'}}>
                                    <Divider
                                        type="horizontal"
                                        orientation="center">
                                        Account Notes :
                                    </Divider>
                                    <table className="border" border={1}>
                                        <tr>
                                            <th>Date & Time</th>
                                            <th>Notes</th>
                                        </tr>
                                        {user_account_notes?.map(note => (
                                            <tr className="">
                                                <>
                                                    <td
                                                        className="border w-[45%]"
                                                        style={{
                                                            padding: '5px',
                                                        }}>
                                                        {moment(
                                                            note?.created_at ||
                                                                moment(),
                                                        ).format(
                                                            'YYYY-DD-MM hh:mm A',
                                                        )}
                                                    </td>
                                                    <td
                                                        className="border"
                                                        style={{
                                                            padding: '5px',
                                                        }}>
                                                        <strong>
                                                            {note?.account_note}
                                                        </strong>
                                                    </td>
                                                </>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            </Col>
                        ) : (
                            <></>
                        )}

                        <Col className="user__top">
                            <List
                                size="small"
                                bordered
                                style={{background: 'rgba(0,0,0,0.03)'}}
                                header={<Title level={5}>Order Info</Title>}>
                                {user.is_requester ? (
                                    <>
                                        <Item
                                            title="Total Completed Requests"
                                            body={user.completed_requests || 0}
                                        />
                                        <Item
                                            title="First Request Placed"
                                            body={
                                                (user.first_request_placed &&
                                                    moment(
                                                        user.first_request_placed,
                                                    ).format(
                                                        'YYYY-MM-DD (hh:mm:ss A)',
                                                    )) ||
                                                'N/A'
                                            }
                                        />
                                        <Item
                                            title="First Request Completed"
                                            body={
                                                (user.first_request_completed &&
                                                    moment(
                                                        user.first_request_completed,
                                                    ).format(
                                                        'YYYY-MM-DD (hh:mm:ss A)',
                                                    )) ||
                                                'N/A'
                                            }
                                        />
                                        <Item
                                            title="Last Request Placed"
                                            body={
                                                (user.last_request_placed &&
                                                    moment(
                                                        user.last_request_placed,
                                                    ).format(
                                                        'YYYY-MM-DD (hh:mm:ss A)',
                                                    )) ||
                                                'N/A'
                                            }
                                        />
                                        <Item
                                            title="Last Request Completed"
                                            body={
                                                (user.last_request_completed &&
                                                    moment(
                                                        user.last_request_completed,
                                                    ).format(
                                                        'YYYY-MM-DD (hh:mm:ss A)',
                                                    )) ||
                                                'N/A'
                                            }
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                                {user.is_gopher ? (
                                    <>
                                        <Item
                                            title="Total Cancelled Requests"
                                            body={user.total_cancelation || 0}
                                        />
                                        <Item
                                            title="First Order Completed"
                                            body={
                                                (user.first_order_completed &&
                                                    moment(
                                                        user.first_order_completed,
                                                    ).format(
                                                        'YYYY-MM-DD (hh:mm:ss A)',
                                                    )) ||
                                                'N/A'
                                            }
                                        />
                                        <Item
                                            title="Last Order Completed"
                                            body={
                                                (user.last_order_completed &&
                                                    moment(
                                                        user.last_order_completed,
                                                    ).format(
                                                        'YYYY-MM-DD (hh:mm:ss A)',
                                                    )) ||
                                                'N/A'
                                            }
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </List>
                        </Col>
                        <Col className="user__top">
                            {favGopher && favGopher.length ? (
                                <List
                                    size="small"
                                    bordered
                                    style={{background: 'rgba(0,0,0,0.03)'}}
                                    header={
                                        <Title level={5}>Fav Gophers</Title>
                                    }>
                                    <Item
                                        // title="Full name"
                                        body={favGopher?.map(item => {
                                            return (
                                                <li
                                                    id={item.fv_gopher_id}
                                                    className="flex flex-row justify-start">
                                                    <h3 className="mr-12">
                                                        {item.fv_gopher_id}
                                                    </h3>
                                                    <Link
                                                        to={`/user/${item.fv_gopher_id}`}>
                                                        {item
                                                            ? item.full_name
                                                            : 'N/A'}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    />
                                </List>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                )}
                {email_tracking && email_tracking.length ? (
                    <div className="container">
                        <Divider type="horizontal" orientation="center">
                            Email Change/Update Tracking
                        </Divider>
                        <ul className="responsive-table">
                            <li className="table-header">
                                <div className="col col-2">Id</div>
                                <div className="col col-2">Old Email</div>
                                <div className="col col-2">New Email</div>
                                <div className="col col-2">Updated On</div>
                            </li>
                            {email_tracking.map(item => (
                                <li key={order.id} className="table-row">
                                    <div className="col col-2" data-label="Id">
                                        {item.id || 'N/A'}
                                    </div>
                                    <div
                                        className="col col-2"
                                        data-label="Old Email">
                                        {item.old_email || 'N/A'}
                                    </div>
                                    <div
                                        className="col col-2"
                                        data-label="New Email">
                                        {item.new_email || 'N/A'}
                                    </div>
                                    <div
                                        className="col col-2"
                                        data-label="Updated On">
                                        {moment(item.updated_on).format(
                                            'YYYY-MM-DD (hh:mm:ss A)',
                                        ) || 'N/A'}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    ''
                )}
            </>
            <List
                size="default"
                bordered
                style={{background: 'rgba(0,0,0,0.05)'}}>
                <>
                    {order.length !== 0 && (
                        <div className="container">
                            <Divider type="horizontal" orientation="center">
                                User's Order Recieved
                            </Divider>
                            <ul className="responsive-table">
                                <li className="table-header">
                                    <div className="col col-1">Id</div>
                                    <div className="col col-2">Requestor</div>
                                    <div className="col col-2">Created On</div>
                                    <div className="col col-2">Status</div>
                                    <div className="col col-2">Title</div>
                                    <div className="col col-2">Description</div>
                                </li>
                                {order.map(order => (
                                    <li key={order.id} className="table-row">
                                        <Link
                                            style={{cursor: 'pointer'}}
                                            to={`/booking/${order.id}`}>
                                            {' '}
                                            <div
                                                className="col col-1"
                                                data-label="Id">
                                                {order.id || 'N/A'}
                                            </div>
                                        </Link>
                                        <Link
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            to={`/user/${order.requestor_id}`}
                                            className="col col-2"
                                            data-label="Requestor">
                                            {order.requestor || 'N/A'}
                                        </Link>
                                        <div
                                            className="col col-2"
                                            data-label="Created On">
                                            {moment(order.created_at).format(
                                                'YYYY-DD-MM hh:mm A',
                                            ) || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Assm state">
                                            {<Tag>{order.aasm_state}</Tag> || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Title">
                                            {order.title || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Description">
                                            {order.description || 'N/A'}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
                {/* gopher_id */}
                <>
                    {orderPlaced.length !== 0 && (
                        <div className="container">
                            <Divider type="horizontal" orientation="center">
                                User's Order placed
                            </Divider>
                            <ul className="responsive-table">
                                <li className="table-header">
                                    <div className="col col-1">Id</div>
                                    <div className="col col-2">Gopher</div>
                                    <div className="col col-2">Created On</div>
                                    <div className="col col-2">Status</div>
                                    <div className="col col-2">Title</div>
                                    <div className="col col-2">Description</div>
                                </li>
                                {orderPlaced.map(order => (
                                    <li key={order.id} className="table-row">
                                        <Link
                                            style={{cursor: 'pointer'}}
                                            to={`/booking/${order.id}`}>
                                            {' '}
                                            <div
                                                className="col col-1"
                                                data-label=" Id">
                                                {order.id || 'N/A'}
                                            </div>
                                        </Link>
                                        <Link
                                            style={{
                                                cursor: 'pointer',
                                                marginLeft: '0.5em',
                                            }}
                                            to={`/user/${order.gopher_id}`}
                                            className="col col-2"
                                            data-label="Gopher">
                                            {order.gopher || 'N/A'}
                                        </Link>
                                        <div
                                            className="col col-2"
                                            data-label="Created On">
                                            {moment(order.created_at).format(
                                                'YYYY-DD-MM hh:mm A',
                                            ) || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Assm state">
                                            {<Tag>{order.aasm_state}</Tag> || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Title">
                                            {order.title || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Description">
                                            {order.description || 'N/A'}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>

                <>
                    {flags.length !== 0 && (
                        <div className="container">
                            <Divider type="horizontal" orientation="center">
                                User Flag(s)
                            </Divider>
                            <ul className="responsive-table">
                                <li className="table-header">
                                    <div className="col col-2">Order Id</div>
                                    <div className="col col-2">Description</div>
                                    <div className="col col-2">Reported On</div>
                                </li>
                                {flags.map(flag => (
                                    <li key={flag.id} className="table-row">
                                        <Link
                                            style={{cursor: 'pointer'}}
                                            to={`/booking/${flag.order_id}`}>
                                            {' '}
                                            <div
                                                className="col col-2"
                                                data-label="Customer Name">
                                                {flag.order_id || 'N/A'}
                                            </div>
                                        </Link>
                                        <div
                                            className="col col-2"
                                            data-label="Amount">
                                            {flag.description || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Payment Status">
                                            {moment(
                                                flag.created_at || moment(),
                                            ).format('YYYY-DD-MM (hh:mm A)')}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>

                <>
                    {reports.length !== 0 && (
                        <div className="container">
                            <Divider type="horizontal" orientation="center">
                                User Report(S)
                            </Divider>
                            <ul className="responsive-table">
                                <li className="table-header">
                                    <div className="col col-2">Order Id</div>
                                    <div className="col col-2">Description</div>
                                    <div className="col col-2">Reported On</div>
                                </li>
                                {reports.map(report => (
                                    <li key={report.id} className="table-row">
                                        <Link
                                            style={{cursor: 'pointer'}}
                                            to={`/booking/${report.order_id}`}>
                                            {' '}
                                            <div
                                                className="col col-2"
                                                data-label="Customer Name">
                                                {report.order_id || 'N/A'}
                                            </div>
                                        </Link>
                                        <div
                                            className="col col-2"
                                            data-label="Amount">
                                            {report.description || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Payment Status">
                                            {moment(
                                                report.created_at || moment(),
                                            ).format('YYYY-DD-MM (hh:mm A)')}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
                <>
                    {reffered.length !== 0 && (
                        <div className="container">
                            <Divider type="horizontal" orientation="center">
                                Reffered accounts
                            </Divider>
                            <ul className="responsive-table">
                                <li className="table-header">
                                    <div className="col col-2">Referral Id</div>
                                    <div className="col col-2">User Name</div>
                                    <div className="col col-2">Referred Id</div>
                                </li>
                                {reffered.map(refer => (
                                    <li key={refer.id} className="table-row">
                                        {/* <Link
                                                style={{cursor: 'pointer'}}
                                                to={`/booking/${refer.order_id}`}> */}{' '}
                                        <div
                                            className="col col-2"
                                            data-label="Customer Name">
                                            {refer.id || 'N/A'}
                                        </div>
                                        {/* </Link> */}
                                        <Link
                                            style={{cursor: 'pointer'}}
                                            to={`/user/${refer.referred_id}`}
                                            className="col col-2"
                                            data-label="Amount">
                                            {refer.referred || 'N/A'}
                                        </Link>
                                        <Link
                                            style={{cursor: 'pointer'}}
                                            to={`/user/${refer.referred_id}`}
                                            className="col col-2"
                                            data-label="Payment Status">
                                            {refer.referred_id || 'N/A'}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
                <>
                    {invites.length !== 0 && (
                        <div className="container">
                            <Divider type="horizontal" orientation="center">
                                Invited Accounts
                            </Divider>
                            <ul className="responsive-table">
                                <li className="table-header">
                                    <div className="col col-2">Email</div>
                                    <div className="col col-2">Telephone</div>
                                    <div className="col col-2">Invited On</div>
                                </li>
                                {invites.map((invite, index) => (
                                    <li key={index} className="table-row">
                                        {/* <Link
                                                style={{cursor: 'pointer'}}
                                                to={`/booking/${refer.order_id}`}> */}{' '}
                                        <div
                                            className="col col-2"
                                            data-label="Customer Name">
                                            {invite.email || 'N/A'}
                                        </div>
                                        {/* </Link> */}
                                        <div
                                            className="col col-2"
                                            data-label="Amount">
                                            {invite.telephone || 'N/A'}
                                        </div>
                                        <div
                                            className="col col-2"
                                            data-label="Payment Status">
                                            {moment(
                                                invite.created_at || moment(),
                                            ).format('YYYY-DD-MM (hh:mm A)')}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
            </List>
            <Modal
                title="Confirm"
                visible={openModal}
                onOk={confirmModal}
                onCancel={closeModal}
                onTitleClick={closeModal}>
                <div
                    className="modal"
                    style={{padding: '5px', marginLeft: '0px'}}>
                    {modalBody}
                </div>
            </Modal>
            <Modal
                title="Why is the account being Deactivated?"
                visible={UserDeactiveModal}
                onOk={confirmDeactivateUser}
                onCancel={closeUserDeactiveModal}
                onTitleClick={closeUserDeactiveModal}>
                <div>
                    <label>Notes :</label>
                    <TextArea
                        rows={4}
                        value={DeactivateNotes}
                        onChange={handleDeactivateNotes}
                    />
                </div>
            </Modal>
            <UserSettings
                visible={userSettingsModal}
                toggleModal={closeUserSettings}
                defaultChecked={ooa}
                setOoaBraodcast={setOoaBraodcast}
            />
            <UserJsonModal
                viewUserJson={viewUserJson}
                setViewUserJson={setViewUserJson}
                userJson={userJson}></UserJsonModal>
        </>
    )
}

const UserJsonModal = ({viewUserJson, setViewUserJson, userJson}) => {
    const [visibleJsonIndex, setVisibleIndex] = useState(null)
    const [selectionArr, setSelectionArr] = useState([])
    const [diffModal, setDiffModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const indexArr = []
    function closeJsonModal() {
        setViewUserJson(false)
    }
    function handleShowButton(index) {
        setVisibleIndex(prevState => (prevState != index ? index : null))
    }

    function jsonComparehandler(index) {
        if (selectionArr.includes(index)) {
            const arr = selectionArr.filter(item => item != index)
            setSelectionArr([...arr])
        } else if (selectionArr.length < 2) {
            setSelectionArr([...selectionArr, index])
        }
    }
    function handalJsonDiffModal() {
        setDiffModal(!diffModal)
    }

    function loadingViewJsonDiffModal() {
        selectionArr.map(index => {
            if (userJson[selectionArr[0]].id > userJson[selectionArr[1]].id) {
                let new_arr = [...selectionArr]
                let pop_value = new_arr.pop()
                new_arr.unshift(pop_value)
                setSelectionArr([...new_arr])
            }
        })
        setLoading(true)
        setTimeout(() => {
            setDiffModal(!diffModal)
            setLoading(false)
        }, 3000)
    }
    return (
        <>
            <Modal
                width={700}
                title="User's JSON"
                visible={viewUserJson}
                onOk={closeJsonModal}
                onCancel={closeJsonModal}
                onTitleClick={closeJsonModal}
                footer={[
                    <Button
                        style={{float: 'left'}}
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={loadingViewJsonDiffModal}
                        disabled={selectionArr.length < 2}>
                        Compare
                    </Button>,
                    <Button key="submit" onClick={closeJsonModal}>
                        Close
                    </Button>,
                ]}>
                {userJson.map((user, index) => {
                    indexArr.push(false)
                    return (
                        <>
                            <UserJsonListComponent
                                selectionArr={selectionArr}
                                userJson={userJson}
                                visibleJsonIndex={visibleJsonIndex}
                                index={index}
                                user={user}
                                handleShowButton={handleShowButton}
                                jsonComparehandler={jsonComparehandler}
                                diffModal={diffModal}
                                handalJsonDiffModal={
                                    handalJsonDiffModal
                                }></UserJsonListComponent>
                        </>
                    )
                })}
            </Modal>
        </>
    )
}

const UserJsonListComponent = ({
    selectionArr,
    userJson,
    visibleJsonIndex,
    index,
    user,
    handleShowButton,
    jsonComparehandler,
    diffModal,
    handalJsonDiffModal,
}) => {
    return (
        <>
            {diffModal && (
                <JsonDiffViewer
                    handalJsonDiffModal={handalJsonDiffModal}
                    firstjson={
                        selectionArr.length ? userJson[selectionArr[0]] : null
                    }
                    secondjson={
                        selectionArr.length ? userJson[selectionArr[1]] : null
                    }></JsonDiffViewer>
            )}
            <div style={{display: 'flex'}}>
                <Checkbox
                    style={{marginRight: '10px', fontSize: '16px'}}
                    checked={selectionArr.includes(index)}
                    disabled={selectionArr.length > 2}
                    onChange={() => jsonComparehandler(index)}></Checkbox>
                <Button
                    style={{marginBottom: '5px'}}
                    key={index}
                    type="primary"
                    block
                    onClick={() => handleShowButton(index)}>
                    {moment(user.created_at).format('YYYY-DD-MM (hh:mm A)')}
                </Button>
            </div>
            <JsonViewComponent
                user={user}
                visible={visibleJsonIndex == index ? visibleJsonIndex : null}
            />
        </>
    )
}

const JsonViewComponent = ({user, visible}) => {
    return (
        <>
            {' '}
            {visible != null && (
                <ReactJson
                    theme={'isotope'}
                    displayDataTypes={false}
                    enableClipboard={false}
                    collapsed={1}
                    src={user.metadata}
                />
            )}
        </>
    )
}

const JsonDiffViewer = ({firstjson, secondjson, handalJsonDiffModal}) => {
    function diffmodalhandler() {
        handalJsonDiffModal(false)
    }
    return (
        <>
            <Modal
                width={800}
                title="User's JSON Deffrence"
                visible={firstjson}
                onOk={diffmodalhandler}
                onCancel={diffmodalhandler}
                onTitleClick={diffmodalhandler}>
                <ReactJsonViewCompare
                    oldData={firstjson.metadata}
                    newData={secondjson.metadata}
                />
                ;
            </Modal>
        </>
    )
}

export default UserView

//export default UserView
