import React from 'react'
import moment from 'moment'
import {Col, Row, List, Tag, Typography} from 'antd'
import {Spin} from 'antd'
import {useMutation, useQuery} from 'react-query'

import Item from './Item'
import * as ratingsQueries from '../../../queries/ratings.queries'
import * as Styled from './counterview.styled'
import {Link} from 'react-router-dom'

const {Title} = Typography

function RatingView({params}) {
    const ratingQuery = useQuery(
        ['id', params.id],
        ratingsQueries.getRatingById,
    )

    const rating = ratingQuery.data?.data.data
    if (ratingQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>
            <div className="order">
                <Col style={{width: '100%'}}>
                    <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}
                        header={<Title level={5}>Basic Info</Title>}>
                        <Item
                            body={
                                <Link to={`/ratings/${rating.id}`}>
                                    {rating.id}
                                </Link>
                            }
                            title="Id "
                        />
                        <Item
                            body={
                                <Link to={`/booking/${rating.order_id}`}>
                                    {rating.order_id}
                                </Link>
                            }
                            title="Order Id "
                        />
                        <Item
                            body={
                                <Link to={`/user/${rating.rated_id}`}>
                                    {rating.rated_name}
                                </Link>
                            }
                            title="Rated Name "
                        />

                        <Item
                            body={
                                <Link to={`/user/${rating.rater_id}`}>
                                    {rating.rater_name}
                                </Link>
                            }
                            title="Rater Name"
                        />
                        <Item body={rating.score || 'N/A'} title="Score" />

                        <Item body={rating.review || 'N/A'} title="Review" />
                    </List>
                </Col>
            </div>
        </>
    )
}

export default RatingView
