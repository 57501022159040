import * as API from '_axios'
import URLS from 'constants/url'
import {ADMIN_URLS} from 'constants/url'

export const getAllBookings = async ({queryKey}) => {
    
    const [, pageNo] = queryKey
    const result = await API.get(
        `${URLS.ADMIN_URLS.getBookings}?pageNo=${pageNo || 0}`,
    )
    return result
}
export const confirmPayout = async ({queryKey}) => {
const data = {
    order_id:  queryKey.id
    
}
console.log(data)
    const result = await API.post(
        `${URLS.ADMIN_URLS.confirmPayout}`, data
    )
    return result
}


export const getBookingByFilter = async ({queryKey}) => {
    const [,pageNo, filters] = queryKey
   
   // const query = new URLSearchParams(filters).toString()
    const result = await API.post(
        `${ADMIN_URLS.getBookingByFilter}?pageNo=${pageNo || 0}`,
         {
            id: filters.id  || null,
            email:filters.email  || null,
            gopher_name :filters.gopher_name  || null,
            requester_name :filters.requester_name  || null,
            title :filters.title  || null,
         }
    )
    return result
}




export const getBookingDetails = async ({queryKey}) => {
    const [, filters] = queryKey

    const query = new URLSearchParams(filters).toString()
    const result = await API.get(
        `${URLS.ADMIN_URLS.getBookingDetails}?${query}`,
    )
    return result
}
