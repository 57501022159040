import React, {useState, useEffect} from 'react'
import {Typography, Button, Space, Input, Row, Tag, Table, Modal, Select, message} from 'antd'
import {Link} from 'react-router-dom'
import {AppstoreAddOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, PlusCircleFilled} from '@ant-design/icons'
import moment from 'moment'
import {useQuery} from 'react-query'

import * as Queries from 'queries/webhook.queries'
import {QUERIES} from 'constants/index'
import DataTable from 'components/DataTable'

import * as Styled from './webhook.styled'
import axios from 'axios'
import fileDownload from 'js-file-download'
import {ReloadOutlined} from '@ant-design/icons'
import { render } from '@testing-library/react'

const {Title} = Typography

function Webhook() {
    const [webhookModal,setWebhookModal]=useState(false)
    const [event,setEvent]=useState('')
    const [endpoint,setEndpoint]=useState('')
    const [webhooks,setWebhooks]=useState([])
    const [modaltitle,setModaltitle]=useState('')
    const [updateWebhookid,setUpdateWebhookid]=useState('')
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'userId',
            render: (val,record, index) => <a type='button' onClick={()=>editWebhook(record)}>{val}</a>,
            // render: (val,record, index) => <Link to={`/otp/${val}`}>{val}</Link>,
            width: 110,
        },
        {
            title: 'Events',
            dataIndex: 'enabled_events',
            key: 'enabled_events',
            render:val=> val.map((item)=><>{item}<br></br></>)
        },
        {
            title: 'Webhook endpoint Url',
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: val => {
                return (
                    <Tag color={val ? (val === 'enabled' ? 'green':'red') : null}>
                        {val }
                    </Tag>
                )
            },
        },
        {
            title: 'Created On',
            dataIndex: 'created',
            key: 'created',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
            responsive: ['md'],
        },
        {
            title:'Action',
            key:'action',
            render: (item) => (
                 <>
                 <div style={{display:'flex',gap:'2px'}}>
                 <Button type="danger" shape="circle" onClick={()=>deleteWebhook(item.id)} icon={<DeleteOutlined />} />
                 <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={()=>editWebhook(item)} />
                 </div>
                  </>
              ),
        }
    ]
    const options=[
        { value: '', label: 'Select Event' },
        { value: 'payout.failed', label: 'Gopher Payout Failed' }
      ]

    const getAllWebhook = async ()=>{
        await Queries.getAllWebHooks().then(({data})=>{
            setWebhooks(data.data)
        }).catch((err)=>{
            message.error(err.message)
        })
   
    }

    const editWebhook = (record)=>{
        setEvent(record.enabled_events[0])
        setUpdateWebhookid(record.id)
        setEndpoint(record.url)
        setModaltitle(`Edit Webhook ID : ${record.id}`)
        setWebhookModal(true)
    }

    const deleteWebhook = async (id)=>{
        await Queries.deleteWebHookById(id).then((res)=>{
            resetFields()
        }).catch((err)=>{
            message.error(err.message)
        })
    }

    const updatewebhook = async()=>{
        if(event && endpoint && updateWebhookid)
        await Queries.updateWebHookById(updateWebhookid,event,endpoint).then(()=>{
            resetFields()
        }).catch((err)=>{
            message.error(err.message)
        })
    }

    const handalurlInput = (e)=>{
      setEndpoint(e.target.value)
    }
    const resetFields = ()=>{
        getAllWebhook()
        setWebhookModal(false)
        setUpdateWebhookid('')
        setModaltitle('')
        setEvent('')
        setEndpoint('')
    }

    const subminWebHook=async ()=>{
        if(event && endpoint)
        await Queries.createWebHook(event,endpoint).then(()=>{
            resetFields()
        }).catch((err)=>{
            message.error(err.message)
        })
    } 

    const closeWebhookModal =()=>{
        resetFields()
    }

    useEffect(() => {
        getAllWebhook()
    }, [])

    const reloadApi = () => {
        getAllWebhook()
    }

    return (
        <>
        
            {/* <div
                style={{
                    textAlign: 'right',
                    marginRight: '30px',
                    marginBottom: '5px',
                }}>
                <Button
                    icon={<ReloadOutlined />}
                    style={{textAlign: 'right'}}
                    onClick={reloadApi}
                    type="primary">
                    Reload
                </Button>
            </div> */}
             <div
                style={{
                    justifyContent:'space-between',
                }}>
                <Button
                    icon={<AppstoreAddOutlined />}
                    style={{marginLeft:'20px'}}
                    onClick={()=>{setWebhookModal(true);setModaltitle('Add Webhook')}}
                    type="primary">
                    Add Webhook
                </Button>
                https://oriented-grown-goblin.ngrok-free.app/api/v1/admin/endpoint/payout_error
                <Button
                    icon={<ReloadOutlined />}
                    style={{float:'right',marginRight:'20px'}}
                    onClick={reloadApi}
                    type="primary">
                    Reload
                </Button>
            </div>

                <Table columns={columns} dataSource={webhooks} />
{/* 
                <Button
                    style={{margin: '2rem', minHeight: '45px', display: 'flex'}}
                    onClick={e => downloadCsv(e)}
                    loading={csv}
                    type="light">
                    <img
                        style={{
                            width: '35px',
                            height: '35px',
                            marginRight: '5px',
                        }}
                        src="/csb.png"
                        alt=""
                    />
                    Download CSV
                </Button> */}
        <Modal title={modaltitle} open={webhookModal} onOk={modaltitle=='Add Webhook'?subminWebHook:updatewebhook} onCancel={closeWebhookModal}
         footer={[
            <Button key="cancel" onClick={closeWebhookModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={modaltitle=='Add Webhook'?subminWebHook:updatewebhook}>
              {modaltitle=='Add Webhook'?'Submit':'Update'}
            </Button>
          ]}
        >
        <div style={{display:'flex',sjustifyContent:'space-between'}}>
        <h1 style={{width:'16%',marginTop:'auto',marginBottom:'auto'}}>Event : </h1>
        <Select
            defaultValue=''
            value={event}
            style={{ width: '85%',marginBottom:'10px',margin:'5px' }}
            options={options}
            onSelect={(value)=>{setEvent(value)}}
        />
        </div>
        <br></br>
        <div style={{display:'flex',sjustifyContent:'space-between'}}>
        <h1 style={{width:'20%',marginTop:'auto',marginBottom:'auto'}}>End Point : </h1>
        <Input placeholder="Enter webhook end point" value={endpoint} allowClear onChange={handalurlInput} />
        </div>
        </Modal>
        </>
    )
}

export default Webhook
